define("ember-dayjs/helpers/dayjs-from", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsFrom extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.extend('relativeTime');
      return this.dayjs.self(params[0]).from(params[1], hash.hideAffix || false);
    }
  }
  _exports.default = DayjsFrom;
});
define("ember-dayjs/helpers/dayjs-format", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsFormat extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      if (hash.inputFormat) {
        this.dayjs.extend('customParseFormat');
      }
      this.dayjs.useLocale(hash.locale || this.dayjs.locale);
      return this.dayjs.self(params[0], hash.inputFormat).locale(hash.locale || this.dayjs.locale).format(params[1]);
    }
  }
  _exports.default = DayjsFormat;
});
define("ember-dayjs/helpers/dayjs-is-same-or-before", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsIsSameOrBefore extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.extend('isSameOrBefore');
      return this.dayjs.self(params[0]).isSameOrBefore(params[1], hash.precision);
    }
  }
  _exports.default = DayjsIsSameOrBefore;
});
define("ember-simple-auth/authenticators/test", ["exports", "rsvp", "ember-simple-auth/authenticators/base"], function (_exports, _rsvp, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    restore(data) {
      return _rsvp.default.resolve(data);
    },
    authenticate(data) {
      return _rsvp.default.resolve(data);
    },
    invalidate() {
      return _rsvp.default.resolve();
    }
  });
  _exports.default = _default;
});
define("ember-dayjs/services/dayjs", ["exports", "@ember/utils", "@ember/service", "@ember/debug", "@glimmer/tracking"], function (_exports, _utils, _service, _debug, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DayjsService = (_class = class DayjsService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "locale", _descriptor, this);
    }
    get self() {
      return dayjs;
    }
    setLocale(locale) {
      this.useLocale(locale);
      this.locale = locale;
    }
    useLocale(locale) {
      (true && !((0, _utils.isPresent)(locale)) && (0, _debug.assert)('Locale cannot be null.', (0, _utils.isPresent)(locale)));
      const localeName = locale.toLowerCase();
      if ((0, _utils.isEqual)(localeName, 'en')) {
        return;
      }
      const fullLocaleName = `dayjs_locale_${localeName.replace('-', '_')}`;
      (true && !((0, _utils.isPresent)(window[fullLocaleName])) && (0, _debug.assert)(`${localeName} locale not found. Please add to your app config in ember-cli-build.js file to include like:
    'ember-dayjs': {
      locales: ['${localeName}'],
    }
   After that, usually you need to restart your application.`, (0, _utils.isPresent)(window[fullLocaleName])));
    }
    setTimeZone(timeZone) {
      this.extend('timezone');
      this.self.tz.setDefault(timeZone);
    }
    resetTimezone() {
      this.setTimeZone();
    }
    extend(plugin) {
      (true && !((0, _utils.isPresent)(plugin)) && (0, _debug.assert)('Plugin name cannot be null.', (0, _utils.isPresent)(plugin)));
      const fullPluginName = `dayjs_plugin_${plugin}`;
      (true && !((0, _utils.isPresent)(window[fullPluginName])) && (0, _debug.assert)(`${plugin} plugin not found. Please add to your app config in ember-cli-build.js file to include like:
    'ember-dayjs': {
      plugins: ['${plugin}'],
    }
    Notice that plugin names are case-sensitive and after that, usually you need to restart your application.`, (0, _utils.isPresent)(window[fullPluginName])));
      this.self.extend(window[fullPluginName]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'en';
    }
  })), _class);
  _exports.default = DayjsService;
});
define("ember-dayjs/helpers/dayjs-is-between", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsIsBetween extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.extend('isBetween');
      return this.dayjs.self(params[0]).isBetween(params[1], params[2], hash.precision, hash.inclusivity);
    }
  }
  _exports.default = DayjsIsBetween;
});
define("ember-truth-helpers/helpers/is-array", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isArray = isArray;
  function isArray(params) {
    for (let i = 0, len = params.length; i < len; i++) {
      if ((0, _array.isArray)(params[i]) === false) {
        return false;
      }
    }
    return true;
  }
  var _default = (0, _helper.helper)(isArray);
  _exports.default = _default;
});
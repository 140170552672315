define("ember-dayjs/helpers/local", ["exports", "ember-dayjs/helpers/utc"], function (_exports, _utc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Local extends _utc.default {
    compute(params, hash) {
      super.compute(params, hash);
      return super.compute(params).local();
    }
  }
  _exports.default = Local;
});